
// Libraries
import { debounce } from 'lodash';
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';

// Components
import Checkbox from 'client/components/forms/Checkbox.vue';
import LoginActionButton from 'client/components/LoginActionButton.vue';
import LoginAlert from 'client/components/LoginAlert.vue';
import LoginTextField from 'client/components/LoginTextField.vue';
import UserLoginStore from 'client/stores/UserLoginStore';

// Services
import LocalStorageService from 'client/util/LocalStorageService';

// Store
import { featureFlags } from 'client/stores/FeatureFlagsModule';
import { mapActions } from 'vuex';

// Constants
import { LocalStorageConsts } from 'client/util/Constants';
import { useLoginLabels } from 'client/util/Constants/LoginLabels';

// Utils
import { datadogRum } from '@datadog/browser-rum-slim';

const {
  userLoginEmail,
} = LocalStorageConsts;

let numToggles = 0;

export default defineComponent({
  name: 'EmailEntry',

  inject: ['$validator'],

  components: {
    Checkbox,
    LoginActionButton,
    LoginAlert,
    LoginTextField,
  },

  props: {
    redirectTo: {
      type: String,
      default: '',
    },
    successMessage: {
      type: String,
      default: '',
    },
    isSecondarySubmitButton: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const uiLabel = useLoginLabels();
    return { uiLabel };
  },

  mounted() {
    this.userLoginStore.password = '';
    this.enableAndroidExperiment = debounce(this.enableAndroidExperiment, 1000);
  },

  data() {
    return {
      hasTracked: false,
      isLoading: false,
    };
  },

  computed: {
    ...mapStores(UserLoginStore),

    disableButton() {
      const errors = this.$validator.errors.items.length > 0;

      return errors || !this.userLoginStore.email.length;
    },
  },

  methods: {
    ...mapActions('FeatureFlagsModule', [
      'setFeatureFlag',
    ]),

    handleFocus() {
      if (!this.hasTracked) {
        this.hasTracked = true;
        // Identify JS errors that cause the page not to load for specific browsers
        datadogRum.addAction('user-ready');
      }
    },

    handleInput(isChecked: boolean) {
      if (!isChecked) {
        LocalStorageService.removeItem(userLoginEmail);
      }

      numToggles += 1;
      this.enableAndroidExperiment();
    },

    // This method is debounced in 'mounted()' so we only trigger based on this secret style
    enableAndroidExperiment() {
      if (numToggles >= 7 && navigator.userAgent.includes('wv')) {
        this.setFeatureFlag({ flagKey: featureFlags.tryAndroid, value: true });
        this.$emit('submitEmail', null);
      }
      numToggles = 0;
    },

    handleClick() {
      this.isLoading = true;
      const { email } = this.userLoginStore;
      const redirectToParam = this.redirectTo;
      const payload = redirectToParam ? { email, redirectTo: redirectToParam } : { email };

      this.$emit('submitEmail', payload);
    },
  },
});
